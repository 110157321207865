import React, { Component } from 'react'
import styled from 'styled-components'

const SpacerContainer = styled.div`
  ${props => (props.height ? `height: ${props.height}${props.unit};` : null)}
  ${props => (props.width ? `width: ${props.width}${props.unit};` : null)}
  transition: all 0.5s ease;
`


class Spacer extends Component {
  render() {
    const { mobile = 0, desktop = 0, direction = 'height', isMobile = false, unit = 'px' } = this.props
    const props = {}
    if (isMobile) {
      props[direction] = mobile
    } else {
      props[direction] = desktop
    }
    props.unit = unit
    return <SpacerContainer {...props} />
  }
}

export default Spacer
