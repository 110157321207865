import React from 'react';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/styles';
import { FaBars } from 'react-icons/fa';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import styles from './style';

type Props = {
  classes: Object,
  options: [],
  open: Boolean,
  onClick: () => void,
  cartQuantity: Number
};

const MobileNavigation = ({ classes, options, onClick, open, cartQuantity }: Props) => (
  <>
    <FaBars className={classes.menuIcon} onClick={onClick} />
    {open && (
      <div className={classes.menuContainer}>
        {options.map((link, i) => (
          <div key={i}>
            <Link
              to={link.to}
              className={classes.navLink}
              activeClassName={classes.navLinkActive}
              exact={'true'}>
              <span>{link.text}</span>
            </Link>
          </div>
        ))}
        <div>
          <Link
            to='/cart'
            className={classes.navLink}
            activeClassName={classes.navLinkActive}
            exact={'true'}
          >
            <Badge badgeContent={cartQuantity} color="error">
              <ShoppingCartIcon />
            </Badge>
          </Link>
        </div>
      </div>
    )}
  </>
);

export default withStyles(styles)(MobileNavigation);
