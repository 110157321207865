import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import withWidth from '@material-ui/core/withWidth';

import { compose, isMobileWidth } from 'utils';
import MainNavigation from './main';
import MobileNavigation from './mobile';
import options from './content';
import styles from './style';
import { useCartContext } from '../../context/cart'

type Props = {
  classes: Object,
  width: string,
};

const Navigation = ({ classes, width }: Props) => {
  const [{ cartQuantity }] = useCartContext()
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.container}>
      {isMobileWidth(width) ? (
        <MobileNavigation
          options={options}
          open={open}
          onClick={() => setOpen(!open)}
          cartQuantity={cartQuantity}
        />
      ) : (
        <MainNavigation options={options} cartQuantity={cartQuantity}/>
      )}
    </div>
  );
};

export default compose(
  withWidth(),
  withStyles(styles)
)(Navigation);
