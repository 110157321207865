import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';

import { Header } from 'components';
import { compose, isMobileWidth, Spacer } from 'utils';
import styles from './style';

require('typeface-roboto');

type Props = {
  classes: Object,
  children: HTMLElement,
  noBackground: Boolean,
};

const Layout = ({ classes, children, noBackground, width }: Props) => (
  <Scrollbars
    autoHide
    autoHideTimeout={1000}
    autoHideDuration={200}
    renderView={props => <div {...props} className={classes.scrollFix} />}
    className={classes.container}>
    <div
      className={classNames(
        classes.pageContainer,
        !noBackground && classes.background
      )}>
      <Header />
      <Spacer mobile={10} desktop={0} isMobile={isMobileWidth(width)}/>
      <Fade in mountOnEnter unmountOnExit>
        <main className={classes.content}>{children}</main>
      </Fade>
      <Spacer mobile={30} desktop={0} isMobile={isMobileWidth(width)}/>

    </div>
  </Scrollbars>
);

export default compose(
  withWidth(),
  withStyles(styles)
)(Layout);
