import React from 'react';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import styles from './style';

type Props = {
  classes: Object,
  options: [],
  cartQuantity: Number
};

const MainNavigation = ({ classes, options, cartQuantity }: Props) => (
  <ul>
    {options.map((link, i) => (
      <li className={classes.menuContainer} key={i}>
        <Link
          to={link.to}
          className={classes.navLink}
          activeClassName={classes.navLinkActive}
          exact={'true'}>
          <span>{link.text}</span>
        </Link>
      </li>
    ))}
    <li className={classes.menuContainer}>
      <Link
        to='/cart'
        className={classes.navLink}
        activeClassName={classes.navLinkActive}
        exact={'true'}
      >
        <Badge badgeContent={cartQuantity} color="error">
          <ShoppingCartIcon />
        </Badge>
      </Link>
    </li>
  </ul>
);

export default withStyles(styles)(MainNavigation);
